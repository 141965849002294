define("discourse/plugins/chat/discourse/services/chat-notification-manager", ["exports", "@ember/service", "discourse/lib/desktop-notifications", "discourse/lib/plugin-api", "discourse-common/config/environment", "discourse-common/utils/decorators"], function (_exports, _service, _desktopNotifications, _pluginApi, _environment, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNotificationManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    _subscribedToCore = true;
    _subscribedToChat = false;
    _countChatInDocTitle = true;
    start() {
      if (!this._shouldRun()) {
        return;
      }
      this.set("_chatPresenceChannel", this.presence.getChannel(`/chat-user/chat/${this.currentUser.id}`));
      this.set("_corePresenceChannel", this.presence.getChannel(`/chat-user/core/${this.currentUser.id}`));
      this._chatPresenceChannel.subscribe();
      this._corePresenceChannel.subscribe();
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.onPageChange(this._pageChanged);
      });
      this._pageChanged();
      this._chatPresenceChannel.on("change", this._subscribeToCorrectNotifications);
      this._corePresenceChannel.on("change", this._subscribeToCorrectNotifications);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (!this._shouldRun()) {
        return;
      }
      this._chatPresenceChannel.off("change", this._subscribeToCorrectNotifications);
      this._chatPresenceChannel.unsubscribe();
      this._chatPresenceChannel.leave();
      this._corePresenceChannel.off("change", this._subscribeToCorrectNotifications);
      this._corePresenceChannel.unsubscribe();
      this._corePresenceChannel.leave();
    }
    shouldCountChatInDocTitle() {
      return this._countChatInDocTitle;
    }
    _pageChanged() {
      if (this.chatStateManager.isActive) {
        this._chatPresenceChannel.enter({
          onlyWhileActive: false
        });
        this._corePresenceChannel.leave();
      } else {
        this._chatPresenceChannel.leave();
        this._corePresenceChannel.enter({
          onlyWhileActive: false
        });
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "_pageChanged", [_decorators.bind]))();
    _coreAlertChannel() {
      return (0, _desktopNotifications.alertChannel)(this.currentUser);
    }
    _chatAlertChannel() {
      return `/chat${(0, _desktopNotifications.alertChannel)(this.currentUser)}`;
    }
    _subscribeToCorrectNotifications() {
      const oneTabForEachOpen = this._chatPresenceChannel.count > 0 && this._corePresenceChannel.count > 0;
      if (oneTabForEachOpen) {
        this.chatStateManager.isActive ? this._subscribeToChat({
          only: true
        }) : this._subscribeToCore({
          only: true
        });
      } else {
        this._subscribeToBoth();
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "_subscribeToCorrectNotifications", [_decorators.bind]))();
    _subscribeToBoth() {
      this._subscribeToChat();
      this._subscribeToCore();
    }
    _subscribeToChat() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        only: false
      };
      this.set("_countChatInDocTitle", true);
      if (!this._subscribedToChat) {
        this.messageBus.subscribe(this._chatAlertChannel(), this.onMessage);
        this.set("_subscribedToChat", true);
      }
      if (opts.only && this._subscribedToCore) {
        this.messageBus.unsubscribe(this._coreAlertChannel(), this.onMessage);
        this.set("_subscribedToCore", false);
      }
    }
    _subscribeToCore() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        only: false
      };
      if (opts.only) {
        this.set("_countChatInDocTitle", false);
      }
      if (!this._subscribedToCore) {
        this.messageBus.subscribe(this._coreAlertChannel(), this.onMessage);
        this.set("_subscribedToCore", true);
      }
      if (opts.only && this._subscribedToChat) {
        this.messageBus.unsubscribe(this._chatAlertChannel(), this.onMessage);
        this.set("_subscribedToChat", false);
      }
    }
    onMessage(data) {
      if (data.channel_id === this.chat.activeChannel?.id) {
        return;
      }
      return (0, _desktopNotifications.onNotification)(data, this.siteSettings, this.currentUser, this.appEvents);
    }
    static #_8 = (() => dt7948.n(this.prototype, "onMessage", [_decorators.bind]))();
    _shouldRun() {
      return this.chat.userCanChat && !(0, _environment.isTesting)();
    }
  }
  _exports.default = ChatNotificationManager;
});