define("discourse/plugins/chat/discourse/routes/chat-direct-messages", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDirectMessagesRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    activate() {
      this.chat.activeChannel = null;
    }
    beforeModel() {
      if (this.site.desktopView) {
        this.router.transitionTo("chat");
      }
    }
    model() {
      return this.chatChannelsManager.directMessageChannels;
    }
  }
  _exports.default = ChatDirectMessagesRoute;
});