define("discourse/plugins/chat/discourse/routes/chat-index", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get hasThreads() {
      if (!this.siteSettings.chat_threads_enabled) {
        return false;
      }
      return this.chatChannelsManager.hasThreadedChannels;
    }
    get hasDirectMessages() {
      return this.chat.userCanAccessDirectMessages;
    }
    activate() {
      this.chat.activeChannel = null;
    }
    async model() {
      return await this.chat.loadChannels();
    }
    async redirect() {
      // on mobile redirect user to the first footer tab route
      if (this.site.mobileView) {
        if (this.siteSettings.chat_preferred_mobile_index === "my_threads" && this.hasThreads) {
          return this.router.replaceWith("chat.threads");
        } else if (this.siteSettings.chat_preferred_mobile_index === "direct_messages" && this.hasDirectMessages) {
          return this.router.replaceWith("chat.direct-messages");
        } else {
          return this.router.replaceWith("chat.channels");
        }
      }

      // We are on desktop. Check for last visited channel and transition if so
      const id = this.currentUser.custom_fields.last_chat_channel_id;
      if (id) {
        return this.chatChannelsManager.find(id).then(c => {
          return this.router.replaceWith("chat.channel", ...c.routeModels);
        });
      } else {
        return this.router.replaceWith("chat.browse");
      }
    }
  }
  _exports.default = ChatIndexRoute;
});